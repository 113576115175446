<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data_enjoy: {},
				static_hero: {},
				showLBL:""
			}
		},
		computed:{
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_how_to_enjoy,
					link: { name: 'BLHowToEnjoy' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				this.showLBL = this.web.lbl_show_more
				AOS.init()
				let endEnjoy = $(window).height();
				$(".section.end-hero").css("height", endEnjoy+"px");
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getEnjoy()
			this.getStaticHero()
		},
		methods: {
			getEnjoy() {
				this.$root.topProgress.done()
				Gen.apirest("/belli-enjoy", {}, (err, resp) => {
					if (err) console.log(err)
					this.data_enjoy = resp.data
				})
			},
			getStaticHero() {
				Gen.apirest("/static-hero", {id:5}, (err, resp) => {
					if (err) console.log(err)
					this.static_hero = resp.data
				})
			},
			showMore(k){
				if(this.data_enjoy[k].showLBL == this.web.lbl_show_more) {
					this.data_enjoy[k].limitWord = 999999999
					this.data_enjoy[k].showLBL = this.web.lbl_show_less
					// this.showLBL = this.web.lbl_show_less
				}
				else {
					this.data_enjoy[k].limitWord = 200
					this.data_enjoy[k].showLBL = this.web.lbl_show_more
					// this.showLBL = this.web.lbl_show_more
				}
			}
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
			<section class="section notopmargin nobottommargin wall-bg">
				<div class="container">
					<BreadCrumb :data="breadCrumb"></BreadCrumb>
					<div class="heading-title">
						<h4>{{web.lbl_bellissimo}}</h4>
						<h2>{{web.lbl_how_to_enjoy}}</h2>
					</div>
					<div class="row">
						<div class="col-md-6" v-for="(v,k) in data_enjoy" :key="k">
							<div class="enjoy-item" data-aos="fade-up" data-aos-duration="1000">
								<div class="row">
									<div class="col-md-7 col-lg-5">
										<div class="ei-num">
											<h2>{{k+1}}</h2>
											<img :src="uploader(v.image)" alt="">
										</div>
									</div>
									<div class="col-lg-7">
										<div class="ei-desc">
											<h4>{{v.title}}</h4>
											<p v-html="v.description.limitChar(v.limitWord,'')"></p>
											<a v-if="v.description.length > 200" href="javascript:;" class="text-red" @click="showMore(k)"><span> ... {{v.showLBL}}</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section notopmargin nobottommargin end-hero redwine-bg"
			:style="'background-image: url('+uploader(static_hero.image_desktop)+')'">
			<div class="eh-desc" data-aos="zoom-out" data-aos-duration="500">
				<img :src="assets('fo_images','end-hero-logo.png')" alt="Cape Discovery Logo">
				<h2 :style="'color:'+static_hero.header_color" class="eh-caption">{{static_hero.title_header}}</h2>
				<router-link :to="{name:'Subscribe'}" class="button button-red">{{web.lbl_taste_it_now}}</router-link>
			</div>
		</section>
		</div>
</template>